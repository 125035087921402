* {
  margin: 0;
  padding: 0;
}
.dashboard-wrapper {
  border: solid 2px black;
  background-color:#fff0e7;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  table {
    width: 100%;
  }
  tbody{
    overflow:scroll;
  }
  
}

.table-header-wrapper {
  width: auto;
  
      td{
      background-color:white;
      color:black;
      border-bottom: solid black 1px;
      border-top: solid black 1px;
      white-space: nowrap;
      padding: 0 15px;
      }
// tr{
//   // width: 20%;
//   td {
    
//     border-bottom: solid black 3px;
//       //  width: auto;
//     }
//   }
}

.grey{
  background-color:#cccccc;
  color: black;
  
}
.white {
  background-color:grey;
  color: black;
  
}
.statusApproved {
  background-color: #3ab240;
  color: black;
  border-bottom: 1px solid black;
}
.statusPending {
  background-color: #e5e853;
  color: black;
  border-bottom: 1px solid black;
}
.statusDeclined {
  background-color: #d83e1c;
  color: black;
  border-bottom: 1px solid black;
}
.statusNew {
  background-color: #278ccf;
  color: black;
  border-bottom: 1px solid black;
}
