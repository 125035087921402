* {
  padding: 0;
  margin: 0;
}
.modal-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: scroll;
  h2{
    margin-bottom:5px;
    color:black;
    font-size: 25px;
  }
}

.inner-modal-container {
    position: relative;
    height: 95%;
    width: 75%;
    background-color: lightslategray;
    border-radius: 5px;
    box-shadow: 2px 1px 10px rgb(32, 32, 32);
    transition: all .3s ease;
    cursor: default;
    overflow: scroll;
  
}
.modal-name {
  display: flex;
  color: white;
  margin-top:1%
}
.modal-content {
  background-color: lightslategray !important;
  width: 90% !important;
  display: flex;
  flex-direction: row;
  margin-top:5%;
  margin-left:5%;
  margin-right:5%;
  h3 {
    font-size: 18px;
  }
}
.modal-content-info{
  color:white;
  margin-left:15px;
}
.content-row-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  h3{
    display: flex;
    justify-content: flex-start;
  }
}
.content-row-2 {
  display: flex;
  flex-direction: column;
  width:50%;
  h3{
    display: flex;
    justify-content: flex-start;
  }
}
.content-notes{
  display: flex;
  height: 250px;
  border: solid white 1px;
  border-radius: 15px;
  
  margin-left:5%;
  margin-right:5%;
  overflow: scroll;
  p{
    margin:5px 20px;
    color:white;
    font-size: 18px;
    text-align: left;
  }
}

.exit-modal {
    position: absolute;
    top:0;
    right: 0;
    padding-top: 5px;
    padding-right: 10px;
    font-size: 22px;
    color: white;
    cursor: pointer;
}

.modal-name{
  display: flex;
  justify-content: center;
  color: white;
}



.inner-modal-container-sending {
  position: relative;
  height: 10%;
  min-height: 125px;
  width: 35%;
  background-color: lightslategray;
  border-radius: 5px;
  box-shadow: 2px 1px 10px rgb(32, 32, 32);
  transition: all .3s ease;
  cursor: default;
  h1{
    padding:20px;
    color:white;
    margin: auto;
  }
}



@media only screen and (max-width: 800px) {

    .inner-modal-container{
        width: 85vw;
        border: none;
    }
    .register-inputs-container {
        input {
            width: 50vw;
        }
    }

}

@media only screen and (max-width: 400px) {

    .inner-modal-container {
        input {
            margin: 20px;
        }
        h1 {
           font-size: 45px; 
        }
    }

    .register-inputs-container {
        justify-content: space-around;
    }

}
.lead-success-btn {
  width: 100px;
  height: 30px;
  font-size:16px;
  border-radius: 10px;
  &:hover {
    background-color:grey;
    color:white;
  }
}
.edit-btn {
  margin-left: 5px;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 16px;
}
.rep-selector {
  margin-bottom:2%;
}
.update-btn {
  margin-left: 5px;
  padding: 5px;
  border-radius:15px;
}
