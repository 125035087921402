* {
  padding:0;
  margin: 0;
}
.Navbar-wrapper {
  background-color:grey;
  display:flex;
  justify-content: space-between;
  flex-flow: row;
  height: 60px;
  width: 100vw;
  // position: fixed;
  h1 {
    margin-top: auto;
    margin-bottom: auto;
    &:hover {
      cursor: pointer;
    }
  }
  
}
.Nav-input {
  height: 25px;
  width: 200px;
  margin-top:auto;
  margin-bottom: auto;
  font-size:20px;
}
.Nav-search {
  display:flex;
  flex-flow:row;
  margin-left: 5%;
  h2{
    font-size: 16px;
    margin: auto 10px;
    cursor: default;
    color: black;
    font-weight: 600;
  }
  img{
    margin-top:auto;
    margin-bottom:auto;
  }
  .search-by {
    margin: auto;
  }
  .search-button {
    height: fit-content;
    border-radius: 0%;
    padding: 2px 10px;
    margin: auto 10px;
  }
}
.Nav-menu {
    margin-right:5.5%;
    margin-top:1%;
    &.active{
      margin-right:5.5%;
      z-index: 2;
    }
    
    h2{
      
      &:hover{
        color:white;
        cursor: pointer;
      }
    }
    ul {
      display: flex;
      justify-content: flex-start;
      flex-flow:column;
      margin-top:.75%;
      margin-right:5%;
      padding-left:10px;
      background-color:grey;
      width: 125px;
      height: auto;
      position: absolute;
      background-color:lightgrey;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 5px;
    }
  ul li {
    display: flex;
    list-style: none;
    font-size: 22px;
    padding-top:10px;
    padding-bottom: 10px;
    &:hover{
      
      width:100%;
      color:blue;
      cursor: pointer;
    }
  }
  
}
.incentive-btn {
  margin: auto;
  font-size: 16px;
  font-weight: bold;
    &:hover{
      color:white;
      cursor: pointer;
    }
  }