.table-data-dates {
  font-size:15px;
}
.component-wrapper {
  display: flex;
}
.main-table-wrapper {
  height: 100vh;
  thead {
    position: sticky;
    top: 0;
  }
  .main-body-wrapper {
    overflow: scroll;
  }
  .table-dates {
    display: flex;
    position: relative;
    cursor: pointer;
    .date-arrow {
      position: absolute;
      right: -20px;
      font-size: 20px;
      &.down {
       transform: rotate(180deg);
       top: -5px
      }
      &.hide {
        display: none;
      }

    }
  }
  .status-select {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  div {
    margin: 5px;
    cursor: pointer;
  }
  .current-page {
    background: black;
    color: white;
    padding: 2px 7px;
  }
}
