.form-details {
  display:flex;
  justify-content: space-between;
  margin:0 5%;
    .form-info {
      display: flex;
      width: 100%;
    }
    .form-inputs {
      width: 65%;
      input {
        width: 100%;
        margin: 2px 0;
        height: 32px;
      }
    }
    select {
      width: 65%;
      margin: 2px 0;
    }
    .input-file {
      margin: 2px auto 2px 5%;
    }
    label {
      overflow: hidden;
      width: 100%;
    }
}