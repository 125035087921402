* {
  padding:0;
  margin:0;
}
.saleReps {
  font-size: 16px;
  margin: auto 0;
}
.Side-wrapper{
  height: 100vh;
  min-width: 200px;
  background-color:#71f268;
}
.Side-wrapper::-webkit-scrollbar {
  display: none;
 }
.side-table-wrapper {
  width: 200px;
  height: 100px;
  margin-top:5%;
  margin-left:2%;
  th{
  background-color:white;
  color:black;
  width: 250px;
  border-bottom: solid black 1px
  }
  td {
    background-color:white;
    border-bottom: solid black 1px;
  }

  
  .side-approved{
      background-color:#3ab240;
    }
  .side-pending{
      background-color:#e5e853;
    }
  .side-declined{
      background-color:#d83e1c;
    }
    .side-new{
      background-color:#278ccf;
    }
  
}
.filter-wrapper {
  margin-top:10%;
}
.filter-name{
  display:flex;
  justify-content: flex-start;
  margin-top:3%;
  margin-left:15%;
  
  input{
    margin-top:1%;
    margin-right:5%;
  }
}
.sideBar-btn {
  outline: none;
  font-size:16px;
  width:150px;
  height: 50px;
  margin-top:10px;
  border-radius: 15px;
}
.admin-change {
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}