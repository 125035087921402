.incentive-wrapper {
  display: flex;
  justify-content: center;
  background-color:#fff0e7;
  min-height: 100vh;
}
.incentive-table {
    margin-top:5%;
    width: 750px;
    margin-bottom: 10%;
    border: solid black 1px;
    height: 80%;
    th{
    background-color:white;
    color:black;
    width: 250px;
    border: solid black 1px;
    }
    td {
      border-top: solid black 1px;
      border-width: 1px;
     
    }
    
   
}