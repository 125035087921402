* {
  padding: 0;
  margin: 0;
}
.agent-wrapper {
  height: 100vh;
  width: 100%;

.agent-body {
  margin-left: auto;
  margin-right:auto;
  height: 100%;
  width: 100%;
  background-color:#fff0e7;
  overflow: scroll;
  
  h2{
    margin-bottom:1%;
  }
  input{
    height: 25px;
    width: 155px;
  }
  
  button {
    margin-bottom:2%;
    margin-top:1%;
    background-color: lightblue;
    border-radius: 10px;
    font-size:16px;
    padding: 5px 10px;
    &:hover{
      background-color: blue;
      cursor: pointer;
    }
  }
  table {
    margin-left:auto;
    margin-right:auto;
    border: solid black 1px;
    background-color: #94fc8d;
    border-radius:5px;
    width: 90%;
    button {
      padding: 2px 4px;
      background-color:#ff5c33;
      color:black;
      border: solid 1px;
      margin: 10px 0;
      &:hover {
        background-color:red;
        cursor: pointer;
      }
    }
    tr{
      border-top: solid 1px;
      td{
        padding: 0 10px;
        font-weight: bold;
        font-size: 16px ;
      }
    }
  }
  .agent-table-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 200px;
  }
  .agent-table {
    width: 45%;
    margin-bottom: 50px;
    font-size: 26px;
    font-weight: 600;
    h2 {
      font-size: 22px;
      font-weight: 300;
    }
  }
}
.newAgentInput {
  width: 30%;
  margin: 1% auto;
  display: flex;
  justify-content: space-between;
  input{
   width: 200px;
   font-size:16px;
   margin: auto 0;
  }
}
.agent-options {
  width: 30%;
  margin: 1% auto;
  display: flex;
  justify-content: space-between;
  select {
    height: 100%;
    margin: auto 0;
    width: 55%;
  }
}
}