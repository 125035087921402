* {
  margin:0;
  padding:0;
}
.landingPage-wrapper {
  position: fixed;
  overflow: scroll;
  height: 100vh;
  width: 100vw;
  background-color:#fff0e7;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  
}
.landingNav {
  height: 60px;
  width: 100vw;
  background-color:grey;
  display: flex;
  justify-content: space-between;
  img {
    height: 60px;
    margin:0;
  }
  h1 {
    margin-right:5%;
    margin-top:auto;
    margin-bottom:auto;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}


.landingForm {
  margin-top: 2%;
  background-color: #94fc8d;
  width: 90%;
  height: auto;
  border:solid 1px black;
  border-radius: 15px;
  margin-left:auto;
  margin-right: auto;
  box-shadow: 5px 5px 5px grey;
  margin-bottom: 50px;
  h1{
    padding-top:5%;
  }
  @media(min-width: 768px){
    width: 60%;
  }
}
.form-fields {
  margin-top:2%;
}
.projected-volume {
  font-size:16px;
}

.left-form {
  display: flex;
  flex-flow: column;
  height: auto;
  margin-left:0%;
 .form-details {
   display:flex;
   justify-content: space-between;
   margin:0 5%;
 }
 h4 {
   min-width: 30%;
   max-width: 30%;
   white-space: nowrap;
   text-align: right;
   overflow: hidden;
 }
}
.form-submit-btn {
  display: flex;
  justify-content: center;
  margin-top:5%;
  padding-bottom: 5%;
  button{
    border-radius: 15px;
    width: 225px;
    height: 50px;
    background-color:orange;
    color:white;
    font-size: 16px;
    border:none;
    outline: none;
    &:hover{
      background-color:lightcoral;
    }
  }
}
.form-txt-box {
  display: flex;
  justify-content: flex-start;
  margin-left: 5%;
  width: 85%;
  
}
.landing-login-button {
  &:hover {
    color:white
  }
}
.anetCheckbox{
  margin:0;
  padding:0;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  width: 65%;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
  
  label{
    display: flex;
  input{
    height: 25px;
    white-space: unset;
    margin-right:3%;
    margin-bottom:5%;
  }
  
  h5{
    margin:0;
    height: 30px;
    font-size:16px;
    display: flex;
    align-self: center;
    margin-right:25%;
    margin-left:2%;
  }
}
}
.anetCheckboxCurrency{
  margin:0;
  padding:0;
  display: flex;
  flex-flow: row;
  
  label{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-left:5%;
    width: 50%;
    align-self: auto;
  input{
    width:15px;
    height: 30px;
    white-space: unset;
    margin-right:4%;
    margin-bottom:5%;
  }
  
  h5{
    margin:0;
    height: 30px;
    font-size:16px;
    display: flex;
    align-self: center;
    margin-right:25%;
    margin-left:2%;
  }
}
}